<template>
    <div class="page-box page">
        <Add :userid="userid" :isWap="isWap"></Add>
        <div style="height:120px"></div>
    </div>
    
</template>

<script>
import add from '../../../components/addClient'  
export default {
    components: {
        'Add': add  //将别名demo 变成 组件 Demo
    },
    data() {
        return {
            userid: "",
            isWap: false
        }
    },
    mounted: function(){
        this.userid = this.$route.query.userid;
        this.isWap = true;
    },
}

</script>
<style>
    .page-box{
        height: 100vh;
        background-color: #fff !important;
        padding: 15px;
    }
    .save .ant-btn {
        position: unset;
        width: 27rem;
        margin-left: calc((100vw - 27rem)/2);
        margin-bottom: 30px;
    }
</style>
